<template>
   <div class="flex-coloumn">
          <div class="device-changes">
            <div v-if="deviceInfo.devicetype=='MOBILE'"  class="image-container">
              <img :src="phoneDevice" />
            </div>

            <div v-else  class="image-container">
              <img :src="tvDevice" />
            </div>

            <p dir="ltr" class="bold-text-device device-detail-name">
              <span class="bold-text-device">{{ deviceInfo.devicename ? deviceInfo.devicename : "" }} </span>
              <!-- <span class="bold-text-device" v-if="deviceInfo.actioncount > 0"> &nbsp; : &nbsp;{{ txtActive }} </span> -->
            </p>
            <p class="device-active-status">
              <span> {{ getRelativeTime(deviceInfo.addedtime) }} </span>
              <span>&nbsp;&nbsp;{{ getLoginLocation(deviceInfo.loginlocation) }}</span>
            </p>
          </div>

          <VueToggles
              height="30"
              width="90"
              marginLeft = "4rem"
              marginBottom = "1rem"
              checkedText="Enabled"
              uncheckedText="Disabled"
              checkedBg="red"
              uncheckedBg="grey"
              :value="isEnabledToggle"
              @click="actDeviceToggle"
          />
        </div>
</template>

<script>
import Vue from 'vue';

import VueToggles from 'vue-toggles';
Vue.component('VueToggles', VueToggles);
import { mapActions, mapGetters } from "vuex";
import { showSnackBar, timeDifference } from "../../../../utilities";

export default {
    data() {
        return {
            tvDevice: require("@/assets/icons/tv.svg"),
            phoneDevice :require("@/assets/icons/phoneicon.svg"),
            isEnabledToggle: false
        }
    },
    computed: {
     ...mapGetters(["getCountryCodesList"]),
    },
    props: ['deviceInfo'],
    methods: {
        ...mapActions(["deviceList", "updateDevice"]),
        getRelativeTime(lastLogin) {
            let dLogin = timeDifference(lastLogin);
            dLogin = (dLogin !== "Invalid date") ? dLogin : "...";

            return dLogin;
        },
        getLoginLocation(countryCode) {
            let theCountry;

            this.getCountryCodesList.forEach((country) => {
                if (country.code === countryCode) {
                theCountry = country.name;
                }
            });

            if (theCountry) {
                return theCountry.toUpperCase();
            } else {
                return null;
            }
        },
        actDeviceToggle () {
            console.log(this.isEnabledToggle)
            this.isEnabledToggle = !this.isEnabledToggle
            this.changeDeviceState()
        },
        changeDeviceState(device, index) {
            const payload = {
                deviceId: this.deviceInfo.deviceid,
                status: {
                    archived: "YES",
                    devicestatus: this.isEnabledToggle == false ?  'DISABLED' : 'ENABLED',
                },
            };

            this.updateDevice(payload)
            .then((response) => {
                if (!response.data.reason) {
                    !this.isEnabledToggle ? showSnackBar(this.$t("Device Successfully Removed"),this.$t('DISMISS')) : showSnackBar(this.$t("Device Successfully Added"),this.$t("DISMISS")) ;
                } else {
                    showSnackBar(this.$t(response.data.reason),this.$t('DISMISS'));
                }
            })
            .catch((err) => {
                console.log("error in updating device Status -> ", err);
            });
        },
    },
    mounted () {
        this.isEnabledToggle = this.deviceInfo.devicestatus === 'DISABLED' ? false : true;

        this.txtActive = this.$t("is Now Active");
    }
}
</script>

<style>
    .vue-toggles {
        margin-left: 4rem;
        margin-bottom: 1rem;
    }
</style>